/** @jsxImportSource @emotion/react */
import { forwardRef, Ref } from 'react'
import { Button, ButtonExtendedProps, Text } from 'grommet'

type LinkRef = HTMLButtonElement | HTMLAnchorElement

function FormSidebarButton(props: ButtonExtendedProps, ref: Ref<LinkRef>) {
  return (
    <Button
      // @ts-expect-error Weird button ref typing
      ref={ref}
      plain
      reverse
      justify="between"
      css={{
        backgroundColor: props.active ? '#ff542e1a' : undefined,
        borderRadius: '0 8px 8px 0',
        textAlign: 'left',
        padding: '.65rem 0',
        paddingLeft: '1.5rem',
      }}
      {...props}
      label={
        <Text size="small" color={props.active ? 'accent-2' : 'text-weak'}>
          {props.label}
        </Text>
      }
    />
  )
}
export default forwardRef<LinkRef, ButtonExtendedProps>(FormSidebarButton)
