import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { runtimeFlags } from 'routes'

export type Flags = {
  forest: boolean
  liveForest: boolean
  widget: boolean
  color?: string
}
export default function useFlags(): Flags {
  const router = useRouter()
  const flags = useMemo<Flags>(
    () => ({
      forest: runtimeFlags.forest in router.query,
      liveForest: runtimeFlags.liveForest in router.query,
      widget: runtimeFlags.widget in router.query,
      color:
        runtimeFlags.color in router.query
          ? `#${router.query[runtimeFlags.color]?.toString().substr(0, 8)}`
          : undefined,
    }),
    [router.query],
  )
  return flags
}
