import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { useClient } from 'react-supabase'

import { routes } from 'routes'
import { supabaseClient } from 'supabaseClient'
import { FormSidebar } from 'components/org/FormSidebarMenu'
import { OrganizationWithTeams } from 'db-tables'

type PanelContext = {
  orgs: OrganizationWithTeams[]
  selectedOrg: OrganizationWithTeams | undefined
  selectOrg: (org: OrganizationWithTeams, callback?: (org: OrganizationWithTeams) => void) => void
  clearOrg: () => void
  refresh: () => Promise<void>
  page: {
    activeIndex: FormSidebar
    setActiveIndex: React.Dispatch<React.SetStateAction<FormSidebar>>
  }
}

const clearStorage = () => {
  clearOrgStorage()
}
const clearOrgStorage = () => {
  window.localStorage.removeItem('org')
}

const restoreOrg = (orgs: OrganizationWithTeams[]) => {
  // Restore org from localStorage
  const orgId = window.localStorage.getItem('org')
  if (orgId) {
    const org = orgs.find((o) => o.id === orgId)
    if (org) {
      return org
    }
    // Bad org
    clearOrgStorage()
  }

  // Auto select first org
  // return orgs.find(() => true)
}

type Props = {
  orgs?: OrganizationWithTeams[]
  initialPage?: FormSidebar
}
function useDefaultContext({
  orgs: initialOrgs = [],
  initialPage = FormSidebar.Profile,
}: Props): PanelContext {
  const client = useClient()
  const router = useRouter()

  const [orgs, setOrgs] = useState(initialOrgs)
  const [activeIndex, setActiveIndex] = useState<FormSidebar>(initialPage)
  const [selectedOrg, selectOrg] = useState<OrganizationWithTeams | undefined>()
  const clearOrg = useCallback(() => {
    selectOrg(undefined)
    clearStorage()
    router.push(routes.group.list)
  }, [router])

  const refresh = useCallback(async () => {
    if (!client.auth.user()) return // Don't fetch org for anon
    const { data } = await supabaseClient
      .from<OrganizationWithTeams>('organizations')
      .select('id, name, domain, legal_name, owner_id')
      .order('name', { ascending: true })

    if (data) {
      setOrgs(data)

      // Restore selected
      const org = restoreOrg(data)
      if (org) {
        selectOrg(org)
      }
    }
  }, [client])

  // Trigger initial load
  useEffect(() => {
    refresh()
  }, [refresh])

  // Sync active page when routing changes
  useEffect(() => {
    setActiveIndex(initialPage)
  }, [initialPage])

  useEffect(() => {
    // Keep selected in localStorage
    if (selectedOrg?.id) window.localStorage.setItem('org', selectedOrg?.id)
  }, [router, selectedOrg])

  return useMemo<PanelContext>(
    () => ({
      orgs,
      refresh,
      selectedOrg,
      selectOrg: (org: OrganizationWithTeams, cb) => {
        selectOrg(org)
        if (cb) return cb(org)
        // List groups if callback is not provided
        if (org?.domain && !router.asPath.includes(org.domain)) {
          router.push(routes.org.group.list(org.domain))
        }
      },
      clearOrg,
      page: { activeIndex, setActiveIndex },
    }),
    [orgs, refresh, selectedOrg, clearOrg, activeIndex, router],
  )
}

export const PanelContext = createContext<PanelContext>(null as any)

type PanelProviderProps = PropsWithChildren<{ initialState: Props }>
export function PanelProvider({ children, initialState }: PanelProviderProps) {
  const { Provider } = PanelContext
  return <Provider value={useDefaultContext(initialState)}>{children}</Provider>
}

export default function useSidebar() {
  const context = useContext(PanelContext)
  if (context === undefined) throw Error('useSidebar must be used within PanelProvider')
  return context
}
