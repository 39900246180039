/** @jsxImportSource @emotion/react */
import Link from 'next/link'
import { Box, Collapsible } from 'grommet'
import { useTranslation } from 'react-i18next'

import useSidebar from 'hooks/useSidebar'
import FormSidebarButton from 'components/layout/sidebar/FormSidebarButton'
import FormSidebarSection from 'components/layout/sidebar/FormSidebarSection'
import FormSidebarSubButton from 'components/layout/sidebar/FormSidebarSubButton'
import { routes } from 'routes'
import router from 'next/router'

export enum FormSidebar {
  // Org
  OrgSettings,
  OrgBilling,
  OrgInvoices,
  OrgPayment,
  CreateOrg,
  // Team
  CreateTeam,
  Members,
  InvitePeople,
  InviteMember,
  // My
  Profile,
  Security,
}

export default function FormSidebarMenu() {
  const { t } = useTranslation()
  const {
    selectedOrg,
    page: { activeIndex, setActiveIndex },
  } = useSidebar()

  return (
    <Box direction="column" gap="small" margin={{ vertical: 'medium', right: 'medium' }}>
      <FormSidebarSection name={t('Personal info')}>
        <Link href={routes.profile} passHref>
          <FormSidebarButton
            label={t('Profile')}
            active={activeIndex === FormSidebar.Profile}
            onClick={() => setActiveIndex(FormSidebar.Profile)}
          />
        </Link>
        <Link href={routes.settings} passHref>
          <FormSidebarButton
            label={t('Data & security')}
            active={activeIndex === FormSidebar.Security}
            onClick={() => setActiveIndex(FormSidebar.Security)}
          />
        </Link>
        <FormSidebarButton
          label={'Billing'}
          active={[
            FormSidebar.OrgBilling,
            FormSidebar.OrgInvoices,
            FormSidebar.OrgPayment,
          ].includes(activeIndex)}
          onClick={() => setActiveIndex(FormSidebar.OrgBilling)}
        />
        <Collapsible
          open={[FormSidebar.OrgBilling, FormSidebar.OrgInvoices, FormSidebar.OrgPayment].includes(
            activeIndex,
          )}>
          <Box margin={{ top: 'small' }}>
            <Link href={routes.pricing} passHref>
              <FormSidebarSubButton
                label={'Pricing'}
                active={activeIndex === FormSidebar.OrgInvoices}
                onClick={() => setActiveIndex(FormSidebar.OrgInvoices)}
              />
            </Link>
            <Link
              href={selectedOrg?.domain ? routes.org.invoices(selectedOrg.domain) : routes.settings}
              passHref>
              <FormSidebarSubButton
                label={'Invoices'}
                active={activeIndex === FormSidebar.OrgInvoices}
                onClick={() => setActiveIndex(FormSidebar.OrgInvoices)}
              />
            </Link>
            <Link
              href={selectedOrg?.domain ? routes.org.billing(selectedOrg.domain) : routes.settings}
              passHref>
              <FormSidebarSubButton
                label={'Payment methods'}
                active={activeIndex === FormSidebar.OrgPayment}
                onClick={() => setActiveIndex(FormSidebar.OrgPayment)}
              />
            </Link>
          </Box>
        </Collapsible>
      </FormSidebarSection>
      <FormSidebarSection
        name={t('basic.organization')}
        action={() => router.push(routes.org.createOrg)}>
        <Collapsible open={!!selectedOrg}>
          <Link
            href={selectedOrg?.domain ? routes.org.settings(selectedOrg.domain) : routes.settings}
            passHref>
            <FormSidebarButton
              label={t('General')}
              active={activeIndex === FormSidebar.OrgSettings}
              onClick={() => setActiveIndex(FormSidebar.OrgSettings)}
            />
          </Link>

          <Link
            href={selectedOrg?.domain ? routes.org.members(selectedOrg.domain) : routes.settings}
            passHref>
            <FormSidebarButton
              label={'Members'}
              active={[FormSidebar.Members, FormSidebar.InviteMember].includes(activeIndex)}
              onClick={() => {
                setActiveIndex(FormSidebar.Members)
              }}
            />
          </Link>
        </Collapsible>
      </FormSidebarSection>
    </Box>
  )
}
