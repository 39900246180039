import { Redirect } from 'next'
import { ParsedUrlQuery } from 'querystring'

import { routes } from 'routes'

export const loginRedirectResponse = (pathname?: string): { redirect: Redirect } => {
  return {
    redirect: {
      destination: loginRedirectUrl(pathname),
      permanent: false,
    },
  }
}

export const loginRedirectUrl = (pathname?: string): string => {
  const loginPage = `${routes.domain}${routes.login}`
  if (pathname && pathname.startsWith(routes.home) && !pathname.startsWith('/_next')) {
    return `${loginPage}?pathname=${pathname}`
  }
  return loginPage
}

export const redirectBackTo = (query: ParsedUrlQuery) => {
  if (
    typeof query.pathname === 'string' &&
    query.pathname.startsWith(routes.home) && // Avoid absolute redirects
    !query.pathname.startsWith('/_next') // Exclude Next.js routes
  ) {
    return query.pathname
  }
  return routes.home
}

export const addRedirectPath = (query: ParsedUrlQuery): string => {
  if (query && 'pathname' in query && typeof query.pathname === 'string') {
    return `?pathname=${encodeURIComponent(query.pathname)}`
  }
  return ''
}
