/** @jsxImportSource @emotion/react */
import { PropsWithChildren, ReactNode } from 'react'
import { Add } from 'grommet-icons'
import { Box, Button, Text } from 'grommet'

import { colors } from 'theme'

type Props = PropsWithChildren<{
  name: ReactNode
  action?: () => void
}>
export default function FormSidebarSection({ name, action, children }: Props) {
  return (
    <>
      <Box
        align="center"
        direction="row"
        justify="between"
        margin={{ top: 'medium' }}
        pad={{ left: 'medium', bottom: 'xsmall' }}
        border={{ side: 'bottom', size: '1px', color: '#eee' }}>
        <Text size="small" weight="bold" color="#333">
          {name}
        </Text>
        {action && (
          <Button
            primary
            onClick={action}
            icon={<Add size="14px" />}
            color="background-contrast"
            css={{
              padding: '4px',
              '&:hover': { backgroundColor: `${colors['accent-2-bg']}` },
              '&:hover svg': { stroke: `${colors['accent-2']}` },
            }}
          />
        )}
      </Box>
      {children}
    </>
  )
}
