import getConfig from 'next/config'
import { ParsedUrlQuery } from 'querystring'

const {
  publicRuntimeConfig: { domain },
} = getConfig()

export { domain }

export enum GroupTabs {
  results = 'results',
  people = 'people',
  emotions = 'emotions',
}
export const runtimeFlags = {
  forest: 'forest',
  liveForest: 'live-forest',
  widget: 'widget',
  color: 'bg',
}

export const flags = (query?: ParsedUrlQuery) => {
  if (!query) return ''

  const flags: string[] = []
  Object.values(runtimeFlags).map((flag) => {
    if (flag in query) {
      flags.push(flag)
    }
  })

  if (flags.length === 0) return ''
  return `?${flags.join('&')}`
}

export const routes = {
  domain,
  home: '/',
  docs: '/docs',
  signup: '/signup',
  login: '/login',
  magicLogin: '/magic-login',
  profile: '/profile',
  settings: '/settings',
  pricing: '/pricing',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  group: {
    list: '/group',
    join: '/group/join',
    create: '/group/create',
    edit: (shortCode: string) => `/group/${shortCode}/edit`,
    preview: (shortCode: string) => `/group/${shortCode}/preview`,
    view: (shortCode: string, query?: ParsedUrlQuery) => {
      return `/group/${shortCode}${flags(query)}`
    },
    results: (shortCode: string, query?: ParsedUrlQuery) => {
      return `/group/${shortCode}/${GroupTabs[GroupTabs.results]}${flags(query)}`
    },
    viewTab: (shortCode: string, tab: GroupTabs, query?: ParsedUrlQuery) => {
      return `/group/${shortCode}/${tab}${flags(query)}`
    },
  },
  org: {
    createOrg: `/create-org`,
    analytics: (domain: string) => `/${domain}/analytics`,
    group: {
      list: (domain: string) => `/${domain}/group`,
      create: (domain: string) => `/${domain}/group/create`,
      edit: (domain: string, shortCode: string) => `/${domain}/group/${shortCode}/edit`,
      view: (domain: string, shortCode: string, query?: ParsedUrlQuery) => {
        return `/${domain}/group/${shortCode}${flags(query)}`
      },
      results: (domain: string, shortCode: string, query?: ParsedUrlQuery) => {
        return `/${domain}/group/${shortCode}/${GroupTabs[GroupTabs.results]}${flags(query)}`
      },
      viewTab: (domain: string, shortCode: string, tab: GroupTabs, query?: ParsedUrlQuery) => {
        return `/${domain}/group/${shortCode}/${tab}${flags(query)}`
      },
    },
    members: (domain: string) => `/${domain}/members`,
    billing: (domain: string) => `/${domain}/billing`,
    invoices: (domain: string) => `/${domain}/invoices`,
    settings: (domain: string) => `/${domain}/settings`,
  },
  privacy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  api: {
    auth: '/api/auth',
  },
  analytics: '/analytics',
}

export const redirectAfterLoginUrl = (query: ParsedUrlQuery) => {
  if (query && 'pathname' in query && typeof query.pathname === 'string') {
    return `${domain}${query.pathname}`
  }
  return `${domain}${routes.home}?oauth`
}
export const redirectAfterRegistrationUrl = () => `${domain}?pathname=${routes.profile}`
