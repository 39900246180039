import Head from 'next/head'
import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'
import { useEffect, useState } from 'react'
import { Provider as SuperbaseProvider } from 'react-supabase'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { Grommet, grommet as grommetTheme, ThemeContext } from 'grommet'

import { customTheme } from 'theme'
import useFlags from 'hooks/useFlags'
import { supabaseClient } from 'supabaseClient'
import { PanelProvider } from 'hooks/useSidebar'
import CoverSpinner from 'components/CoverSpinner'
import { AuthProvider } from 'providers/AuthProvider'
import useGTM, { currentLocation } from 'utils/useGTM'

import 'styles/global.scss'

function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const flags = useFlags()
  const { initialize, trackEvent } = useGTM()
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    initialize({
      events: {
        user_lang: i18n.language,
        original_location: currentLocation(),
      },
    })

    const onRouteChangeComplete = (url: string) => {
      setLoading(false)
      trackEvent({
        event: 'page_view',
        user_lang: i18n.language,
        page_title: document.title,
        page_pathname: url,
        page_location: currentLocation(),
      })
    }
    const onRouteChangeStart = () => setLoading(true)
    const onRouteChangeError = () => setLoading(false)

    // Attach listeners
    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeError', onRouteChangeError)
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Detach listeners
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeError', onRouteChangeError)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [i18n.language, router.events, initialize, trackEvent])

  return (
    <Grommet full theme={grommetTheme} background={flags.color ?? 'background-contrast'}>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />

        {/* Generated with https://realfavicongenerator.net/ */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-config" content="/static/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <ThemeContext.Extend value={customTheme}>
        {loading && <CoverSpinner />}
        <SuperbaseProvider value={supabaseClient}>
          <PanelProvider initialState={{ initialPage: pageProps?.initialPage }}>
            <AuthProvider
              initialState={{
                user: pageProps.user,
                session: pageProps.session,
              }}>
              <Component {...pageProps} />
            </AuthProvider>
          </PanelProvider>
        </SuperbaseProvider>
      </ThemeContext.Extend>
    </Grommet>
  )
}

export default appWithTranslation(CustomApp)
