import { useRouter } from 'next/router'
import { Session } from '@supabase/gotrue-js'
import { useAuthStateChange, useClient } from 'react-supabase'
import { createContext, PropsWithChildren, useEffect, useState } from 'react'

import { routes } from 'routes'
import { User } from 'db-tables'
import useSidebar from 'hooks/useSidebar'
import { redirectBackTo } from 'util/login'

export type AuthState = {
  user: User | null
  session: Session | null
}
const defaultState: AuthState = { session: null, user: null }
export const AuthContext = createContext<AuthState>(defaultState)

type AuthProviderProps = PropsWithChildren<{
  initialState?: AuthState
}>

export function AuthProvider({ children, initialState }: AuthProviderProps) {
  const router = useRouter()
  const client = useClient()
  const { refresh, clearOrg } = useSidebar()
  const session = client.auth.session()
  const [state, setState] = useState<AuthState>(initialState ?? defaultState)

  useEffect(() => {
    setState({
      session,
      user: session?.user ?? initialState?.user ?? null,
    })
  }, [session, initialState, client.auth])

  useAuthStateChange(async (event, session) => {
    console.log(`Supabase auth event: ${event}`, session)
    // console.log(event)

    // Send session to routes.api.auth route to set the auth cookie.
    await fetch(routes.api.auth, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin',
      body: JSON.stringify({ event, session }),
    })

    if (event === 'SIGNED_OUT') {
      setState(defaultState)
      clearOrg()
      router.push(routes.home)
    } else if (event === 'PASSWORD_RECOVERY') {
      router.push(routes.resetPassword + window.location.hash, { hash: window.location.hash })
    } else if (event === 'USER_UPDATED') {
      setState({
        session,
        user: session?.user ?? initialState?.user ?? null,
      })
    } else {
      setState({
        session,
        user: session?.user ?? initialState?.user ?? null,
      })
      await refresh()
      router.push(redirectBackTo(router.query))
    }
  })

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}
