/** @jsxImportSource @emotion/react */
import { Button, ButtonExtendedProps, Text } from 'grommet'
import { FormNext } from 'grommet-icons'

export default function FormSidebarSubButton(props: ButtonExtendedProps) {
  return (
    <Button
      plain
      gap="none"
      justify="start"
      css={{
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        textAlign: 'left',
        padding: '.15rem 0',
        marginLeft: '1rem',
      }}
      icon={<FormNext color={props.active ? 'text' : 'lightgray'} />}
      {...props}
      label={
        <Text size="small" weight={props.active ? 'bold' : undefined}>
          {props.label}
        </Text>
      }
    />
  )
}
