import { base, ThemeType } from 'grommet'
import { Colors } from 'grommet/themes/base'

export const colors: Colors = {
  ...base.global?.colors,
  brand: '#320064',
  focus: '#1AD2F2',
  violet: '#7D4CDB',
  'accent-1': '#E76F51',
  'accent-2': '#FF542E',
  'accent-2-bg': '#ff542e1a',
  'accent-3': '#264653',
  'accent-4': '#F4A261',
  'neutral-1': '#606c38',
  'neutral-2': '#1d3557',
  'neutral-3': '#457b9d',
  'status-critical': '#e63946',
  'status-error': '#FF542E',
  'status-warning': '#ffb300',
  'status-ok': '#0CAE47',
  'status-unknown': '#888',
  'background-contrast': '#f2f2f2',
  'background-contrast-tr': '#c2c2c245',
  'scope-public': '#188038',
  'scope-unlisted': '#5f6368',
  'scope-organization': '#1967d2',
  'text-xweak': {
    light: 'inherit',
  },
  icon: {
    light: '#888',
  },
}

export const customTheme: ThemeType = {
  global: {
    focus: { outline: { size: '0' } },
    colors,
    elevation: {
      light: {
        // @ts-expect-error custom elevation type
        breeze: '8px 0px 16px 0px rgb(0 0 0 / 5%)',
      },
    },
    input: {
      font: { size: 'small' },
    },
  },
  formField: {
    label: {
      size: 'xsmall',
      requiredIndicator: ' *',
    },
    error: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      size: 'xsmall',
    },
    round: 'xsmall',
    border: {
      side: 'all',
    },
  },
  button: {
    border: { radius: '.25rem' },
    size: {
      small: {
        border: {
          radius: '.25rem',
        },
      },
      medium: {
        border: {
          radius: '.25rem',
        },
      },
      large: {
        border: {
          radius: '.25rem',
        },
      },
    },
  },
  calendar: {
    small: { lineHeight: 2 },
  },
  textInput: {
    // extend: () => `
    //   padding: .65rem;
    //   border-radius: .25rem;
    // `,
  },
  fileInput: {
    message: {
      margin: { horizontal: 'small' },
      extend: `display: none;`,
    },
    border: { style: 'hidden' },
  },
  rangeInput: {
    track: {
      color: 'brand',
    },
  },
  tabs: {
    header: {
      // background: 'red',
    },
  },
  tab: {
    pad: 'small',
    active: {
      background: {
        color: 'black',
        opacity: 'weak',
      },
    },
    hover: {
      background: {
        color: 'white',
        opacity: 'strong',
      },
    },
    border: { size: 'none' },
    extend: () => `
      border-radius: 0.5rem;
    `,
  },
}
export default customTheme
