import getConfig from 'next/config'
import { default as TagManager, TagManagerArgs } from 'react-gtm-module'

const gtmId = getConfig().publicRuntimeConfig.GTM_ID
const appVersion = 'v2'

export type EventType =
  | 'page_view' // GA event name
  | 'RouteChange'
  | 'ErrorRouteChange'
  | 'ChangeLanguage'
  | 'Login'
  | 'ErrorLogin'
  | 'MagicLogin'
  | 'ErrorMagicLogin'
  | 'Register'
  | 'ErrorRegister'
  | 'UserUpdatedProfile'
  | 'ErrorUserUpdatedProfile'
  | 'UserResetPassword'
  | 'ErrorUserResetPassword'
  | 'UserChangePassword'
  | 'CreateGroupModalOpen'
  | 'CreateGroupModalClose'
  | 'CreateGroupModalToggle'
  | 'CreateGroup'
  | 'ErrorCreateGroup'
  | 'UpdateGroup'
  | 'ErrorUpdateGroup'
  | 'DeleteEmptyMoodGroup'
  | 'CreateOrg'
  | 'ErrorCreateOrg'
  | 'CreateTeam'
  | 'ErrorCreateTeam'
  | 'InviteOrg'
  | 'ErrorInviteOrg'
  | 'CopyGroupUrl'
  | 'SubmitPlot'
  | 'ErrorSubmitPlot'
  | 'ViewResultsFromShareBox'
  | 'ViewResultsFromPlotPage'
  | 'PlotSubmitMoodFromResults'
  | 'PlotClick'
  | 'PlotDrag'
  | 'PlotResultTabWords'
  | 'PlotResultTabBubbles'
  | 'PlotResultTabPalette'
  | 'PlotResultTabList'
  | 'WidgetPlotResultTabWords'
  | 'WidgetPlotResultTabBubbles'
  | 'WidgetPlotResultTabPalette'
  | 'DialPlotDrag'
  | 'DialPlotClick'
  | 'DialPlotMouseWheel'
  | 'AutocompleteWordPick'
  | 'EmotionWordListPick'
  | 'DonationMoveSlider'
  | 'SaveAsImage'
  | 'CheckinStart'
  | 'CheckinNext'
  | 'CheckinLast'
  | 'CheckinReplay'
  | 'SubmitAnonDisplayName'
  | 'DeselectEmotionWord'
  | 'SelectEmotionWord'
  | 'BrowseEmotionsButton'
  | 'ShowMoreEmotions'
  | 'PlotAsPanelOpen'
  | 'PlotAsPanelClose'
  | 'PlotAsAnonymous'
  | 'PlotAsDisplayName'
  | 'PlotAsUser'
  | 'PlotAsSelectAnonymous'
  | 'PlotAsSelectDisplayName'
  | 'PlotAsSelectUser'
  | 'AddJournalContent'
  | 'ErrorResetPassword'
  | 'ResetPassword'
  | 'ErrorForgotPassword'
  | 'ForgotPassword'
  | 'ExportCsvFromGroupDetails'
  | 'ExportCsvFromGroupList'
  | PaddleEvent
  | 'OpenGuides'
  | 'LikeGuides'
  | 'DislikeGuides'

export type DataEvent = { event: EventType; [key: string]: unknown }

export const gtmInitialize = (params?: Partial<TagManagerArgs>) => {
  return TagManager.initialize({ gtmId, ...params })
}
export const trackEvent = (dataLayer: DataEvent, dataLayerName?: string) => {
  return TagManager.dataLayer({
    dataLayer: { appVersion, ...dataLayer },
    dataLayerName,
  })
}

export default function useGTM() {
  return {
    gtmId,
    initialize: gtmInitialize,
    trackEvent,
  }
}

export const currentLocation = () => {
  return (
    document.location.protocol +
    '//' +
    document.location.hostname +
    document.location.pathname +
    document.location.search
  )
}
