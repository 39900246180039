/** @jsxImportSource @emotion/react */
import { Box, BoxProps, Spinner, SpinnerProps } from 'grommet'

type Props = Pick<SpinnerProps, 'size'> &
  Pick<BoxProps, 'round'> & {
    inset?: number
  }
export default function CoverSpinner({ round = true, inset = 0, size = 'large' }: Props) {
  return (
    <Box
      round={round}
      justify="center"
      direction="column"
      css={{ position: 'absolute', inset, zIndex: 100 }}
      background={{ color: 'white', opacity: 'strong' }}>
      <Spinner size={size} margin="0 auto" color="violet" />
    </Box>
  )
}
